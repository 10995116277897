import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { PageWrapper } from "~components/Core"
import HeroSection from "~sections/app/Hero"
import HeaderButton from "~sections/app/Header"
import ServicesSection from "~sections/app/Services"
import AboutSection from "~sections/app/About"
import ContentSectionOne from "~sections/app/ContentOne"
import PricingSection from "~sections/app/Pricing"
import TestimonialSection from "~sections/app/Testimonial"
import PromoSection from "~sections/app/Promo"
import CtaSection from "~sections/app/Cta"
import FooterFour from "~sections/Blog/Footer/FooterSection"
import { StaticImage as Img } from 'gatsby-plugin-image'


const header = {
  headerClasses:
    "site-header site-header--menu-end light-header site-header--sticky",
  containerFluid: false,
  // buttonBlock : <HeaderButton className="d-none d-sm-flex" children="SignFree SEO Analysis"/>,
}

export default function HomeApp() {
  return (
    <PageWrapper headerConfig={header}>
      <div style={{ padding: "2rem" }}>
        <h1 style={{ marginTop: "8rem", marginBottom: "2rem" }}>Luna's Story</h1>

        <Row>
        <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
          <p>
            In January of 2020, my fiance and I awoke to a serious rumbling
            sound that came from our dog’s crates. What we found horrified us.
            At 2:33 in the morning, our Australian Shepherd, Luna, was having a
            seizure. She had never had one before and we had no idea what we
            were supposed to do. We tried to make sure she was safe and when she
            came down, we made a plan to get help. After talking with her
            veterinarian, we were informed that one seizure is somewhat common
            in dogs, especially Aussies like Luna. If no more seizures happened,
            we wouldn’t need to take any action.
          </p>

          <p>
            A month went by and we thought we were in the clear. Unfortunately,
            another seizure happened. We talked with Luna’s veterinarian again
            and found that she would likely need to start taking medication for
            life, and it was imperative that she took it every day during a
            specific time window. We agreed. Anything to help our dog.{" "}
          </p>

          <p>
            At first We struggled with communicating between each other. Did she
            get her medications this morning? Unfortunately, as we figured out
            how to better communicate those details, she continued having
            seizures. We tried several medications and found that Luna’s
            veterinarian would want regularly details: photos, videos, journal
            entries for her seizures explaining how long they lasted and more.
          </p>

          <p>
            We tried keeping a physical journal and emailing photos and videos,
            but ran into several issues. As a software engineer by trade, Luna’s
            adoptive father set out to build a better solution. Enter: Luna
            Journal.
          </p>
        </Col>

        <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10 flex flex-column">
            <Img className="w-50" src="../assets/image/luna/luna_puppy.jpg" alt="about" layout="fullWidth" placeholder="blurred"/>

            <Img className="w-50" src="../assets/image/luna/luna_toy.jpg" alt="about" layout="fullWidth" placeholder="blurred"/>
        </Col>
        </Row>
        
      </div>
      <FooterFour />
    </PageWrapper>
  )
}
